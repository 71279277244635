import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  COMMON_FORM_FIELD_CLASSES,
  FORM_FIELD_VARIANT_TYPES
} from 'dpl/shared/form/utils/constants';

import ErrorWrapper from './ErrorWrapper';
import Label from './Label';

export const VARIANT_CLASSES = {
  [FORM_FIELD_VARIANT_TYPES.LARGE]: `${COMMON_FORM_FIELD_CLASSES} pv4 ph4 pv5-md fw-medium`,
  [FORM_FIELD_VARIANT_TYPES.REGULAR]: `${COMMON_FORM_FIELD_CLASSES} pv2 ph2`
};

function handleKeyPress(event) {
  // Does not allow non-numeric values for number input
  if (/[^0-9-.]/.test(event.key)) {
    event.preventDefault();
  }
}

function handleOnWheel(event) {
  // Prevent value change on scroll for number inputs
  event.target.blur();
}

const SimpleInput = React.forwardRef(
  (
    {
      autoComplete,
      displayErrors,
      errors,
      id,
      label,
      name,
      onChange,
      required,
      value,
      variant,
      ...inputProps
    },
    ref
  ) => {
    const [cursorPosition, setCursorPosition] = useState();
    const inputRef = useRef();

    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      if (!inputRef) {
        return;
      }
      if (!inputRef.current) {
        return;
      }

      try {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      } catch (err) {
        // ! Moving the cursor has been purposeful·ly removed from some input
        // ! types (e.g. email, number). To prevent future revocations from
        // ! breaking the input catch all related errors.
      }
    }, [ref, cursorPosition, value]);

    return (
      <div className="Input">
        {label && <Label htmlFor={id} required={required} text={label} />}
        <ErrorWrapper isShown={displayErrors} errors={errors}>
          <input
            // autoComplete "off" does not prevent the user from autofilling but "nope" does
            autoComplete={autoComplete === 'off' ? 'nope' : autoComplete}
            className={classnames('Input__input', VARIANT_CLASSES[variant])}
            data-test-id="form-input"
            id={id}
            name={name}
            required={required}
            onChange={event => {
              onChange(event);
              setCursorPosition(event.target.selectionStart);
            }}
            onKeyPress={inputProps.type === 'number' ? handleKeyPress : null}
            onWheel={inputProps.type === 'number' ? handleOnWheel : null}
            value={value ?? ''}
            {...inputProps}
            ref={inputRef}
          />
        </ErrorWrapper>
      </div>
    );
  }
);

SimpleInput.displayName = 'SimpleInput';

SimpleInput.propTypes = {
  autoComplete: PropTypes.string,
  displayErrors: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** One of: "regular" or "large". */
  variant: PropTypes.oneOf(Object.values(FORM_FIELD_VARIANT_TYPES))
};

SimpleInput.defaultProps = {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values
  autoComplete: null,
  displayErrors: false,
  errors: [],
  id: null,
  label: null,
  required: false,
  type: 'text',
  value: null,
  variant: FORM_FIELD_VARIANT_TYPES.REGULAR
};

export default SimpleInput;
